import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/instantglow/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/instantglow/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/instantglow/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/instantglow/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/instantglow/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/instantglow/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-banner-es-instant-glow.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-es-instant-glow-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN INSTANT GLOW<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Άμεσα Προστατευμένη &amp; Όμορφη</h3>
                      <p>
                        Τώρα, η επιδερμίδα σου μπορεί να είναι λαμπερή άμεσα και
                        για όλο το καλοκαίρι, ενώ ταυτόχρονα να είναι
                        προστατευμένη από τον ήλιο! Το PIZ BUIN INSTANT GLOW
                        <sup>®</sup> περιέχει λεπτές πέρλες λάμψης που
                        αντανακλούν το φώς και φωτίζουν άμεσα την επιδερμίδα
                        χαρίζοντάς της έναν όμορφο χρυσαφένιο φυσικό τόνο, ενώ
                        ταυτόχρονα, η προηγμένη προστασία PIZ BUIN<sup>®</sup>{" "}
                        κατά των ακτινών UVA/UVB διασφαλίζει ότι λαμβάνετε
                        αποτελεσματική αντηλιακή προστασία. Δεν πρόκειται για
                        self tan προϊόν.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="skin-illuminating-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-instant-glow-skin-illuminating-sun-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>Skin illuminating sun spray</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Σπρέι με σύνθεση που απορροφάται άμεσα και δεν αφήνει
                          την αίσθηση ότι «κολλάει». Χάρη στην καινοτόμο
                          τεχνολογία της συσκευασίας, το PIZ BUIN INSTANT GLOW
                          <sup>®</sup> επιτρέπει την εφαρμογή υπό γωνία. Δεν
                          πρόκειται για self tan προϊόν.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Απλώστε επαρκή ποσότητα ομοιόμορφα πριν την έκθεση
                          στον ήλιο. Η μείωση της ενδεδειγμένης ποσότητας
                          περιορίζει σημαντικά το επίπεδο προστασίας.
                          Επαναλάβετε συχνά την εφαρμογή, ιδιαίτερα μετά από
                          εφίδρωση, κολύμπι ή σκούπισμα με πετσέτα. Αποφύγετε
                          τον μεσημεριανό ήλιο και την παρατεταμένη έκθεση στον
                          ήλιο ακόμα και με τη χρήση αντηλιακού. Κρατήστε τα
                          βρέφη και τα παιδιά μακριά από την απευθείας έκθεση
                          στην άμεση ακτινοβολία του ήλιου. Μην ψεκάζετε
                          απευθείας στο πρόσωπο. Για χρήση στο πρόσωπο, ψεκάστε
                          στα χέρια σας και απλώστε.{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                BETA CAROTENE
                                <div className="textBlock bottom">
                                  <p>
                                    Η β-καροτίνη είναι μία πορτοκαλί χρωστική
                                    ουσία που βρίσκεται σε αφθονία σε φυτά και
                                    φρούτα. Βοηθά στο να προσφέρει άμεσα στην
                                    επιδερμίδα φυσικό χρώμα και υγιή εμφάνιση.
                                    Μπορεί να αφαιρεθεί εύκολα πλένοντας με
                                    σαπούνι. Δεν περιέχει self – tan παράγοντες.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fa5146555d82b0de6956d3ffaf04bf1c_f91.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CARAMEL
                                <div className="textBlock bottom">
                                  <p>
                                    Το καραμελόχρωμα χαρίζει ένα bronze χρώμα
                                    και δίνει στην επιδερμίδα φυσικό χρώμα και
                                    υγιή εμφάνιση. Μπορεί να αφαιρεθεί εύκολα
                                    πλένοντας με σαπούνι. Δεν είναι περιέχει
                                    self – tan παράγοντες.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4bca608325f7d1f697e7783639540cfe_f92.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                LIGHT REFLECTING PEARLS
                                <div className="textBlock bottom">
                                  <p>
                                    Oι λεπτές πέρλες λάμψης που αντανακλούν το
                                    φως συνδυάζουν μία προηγμένη τεχνολογία
                                    επίστρωσης με ένα χρυσαφένιο χρώμα. Χάρη
                                    στην τεχνολογία πολλαπλών στρώσεων, το φως
                                    αντανακλάται σε κάθε στρώση, χαρίζοντας πιο
                                    έντονη αντανάκλαση και καθαρότητα χρώματος.
                                    Προσφέρει ένα εντυπωσιακό χρυσαφένιο τόνο σε
                                    συνδυασμό με αποτελεσματική προστασία και
                                    οδηγεί σε ένα άμεσα λαμπερό και φυσικό
                                    χρώμα. Δεν είναι περιέχει self – tan
                                    παράγοντες.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/197557fee29adfef39ee77a61900c7bb_f90.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="skin-illuminating-sun-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/a9f4431914fd8e64304c5d8eaae83f65_f542.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SKIN ILLUMINATING SUN LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Aντηλιακή προστασία που διαθέτει μια καινοτόμο
                          τεχνολογία η οποία προσφέρει ένα εφέ λάμψης στην
                          επιδερμίδα, φωτίζοντάς την άμεσα, ενώ ενισχύει το
                          φυσικό της τόνο. Εμπλουτισμένο με απαλές πέρλες που
                          αντανακλούν το φως, αυτό το γαλάκτωμα σώματος χαρίζει
                          άμεσα λάμψη στην επιδερμίδα σας με μία απαλή χρυσή
                          απόχρωση που ενισχύει το φυσικό χρώμα της επιδερμίδα
                          σας. Προσφέρει άμεση και αποτελεσματική αντηλιακή
                          προστασία χάρη στα προηγμένα φίλτρα UVA/UVB. Η μη
                          λιπαρή σύνθεσή του που δεν κολλάει, απορροφάται άμεσα
                          και ενυδατώνει την επιδερμίδα. Σημείωση: Δεν περιέχει
                          self tan.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Εφαρμόστε ομοιόμορφα μια γενναιόδωρη ποσότητα πριν την
                          έκθεση στον ήλιο. Μειωμένη ποσότητα μειώνει σημαντικά
                          το επίπεδο προστασίας. Επαναλάβετε συχνά την εφαρμογή,
                          ειδικά μετά από εφίδρωση, κολύμπι και σκούπισμα με
                          πετσέτα. Αποφύγετε το μεσημεριανό ήλιο και την
                          παρατεταμένη παραμονή στον ήλιο, ακόμα και με τη χρήση
                          αντηλιακού. Κρατήστε βρέφη και παιδιά μακριά από την
                          άμεση έκθεση στην ακτινοβολία του ήλιου.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                BETA CAROTENE
                                <div className="textBlock bottom">
                                  <p>
                                    Η β-καροτίνη είναι μία πορτοκαλί χρωστική
                                    ουσία που βρίσκεται σε αφθονία σε φυτά και
                                    φρούτα. Βοηθά στο να προσφέρει άμεσα στην
                                    επιδερμίδα φυσικό χρώμα και υγιή εμφάνιση.
                                    Μπορεί να αφαιρεθεί εύκολα πλένοντας με
                                    σαπούνι. Δεν περιέχει self – tan παράγοντες.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fa5146555d82b0de6956d3ffaf04bf1c_f91.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CARAMEL
                                <div className="textBlock bottom">
                                  <p>
                                    Το καραμελόχρωμα χαρίζει ένα bronze χρώμα
                                    και δίνει στην επιδερμίδα φυσικό χρώμα και
                                    υγιή εμφάνιση. Μπορεί να αφαιρεθεί εύκολα
                                    πλένοντας με σαπούνι. Δεν είναι περιέχει
                                    self – tan παράγοντες.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4bca608325f7d1f697e7783639540cfe_f92.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                LIGHT REFLECTING PEARLS
                                <div className="textBlock bottom">
                                  <p>
                                    Oι λεπτές πέρλες λάμψης που αντανακλούν το
                                    φως συνδυάζουν μία προηγμένη τεχνολογία
                                    επίστρωσης με ένα χρυσαφένιο χρώμα. Χάρη
                                    στην τεχνολογία πολλαπλών στρώσεων, το φως
                                    αντανακλάται σε κάθε στρώση, χαρίζοντας πιο
                                    έντονη αντανάκλαση και καθαρότητα χρώματος.
                                    Προσφέρει ένα εντυπωσιακό χρυσαφένιο τόνο σε
                                    συνδυασμό με αποτελεσματική προστασία και
                                    οδηγεί σε ένα άμεσα λαμπερό και φυσικό
                                    χρώμα. Δεν είναι περιέχει self – tan
                                    παράγοντες.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/197557fee29adfef39ee77a61900c7bb_f90.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/hydro-infusion/" target="_self">
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/sensitive/" target="_self">
                      Sensitive
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/gr/our-products/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
       
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
